import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    Grid,
    GridItem,
    Center,
    Text 
} from '@chakra-ui/react'

import { useRef } from 'react';

function ServiceModal({isOpen, onClose, title}) {

    const windowSize = useRef([window.innerWidth, window.innerHeight]);

    return <>
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent 
                maxH={windowSize.current[1] - 100}
                maxW={windowSize.current[0] - 100}
                h="80%"
                w="80%"
            >
                <ModalHeader>{title}</ModalHeader>
                <ModalCloseButton />
                <ModalBody border={"1px"} borderColor={"green"}>
                    <Grid templateColumns="repeat(4, 10fr)" w="100%" h="100%">
                        <GridItem colSpan={"1"} rowSpan={"3"} border="1px" borderColor={"red"}>
                            <Center h="100%">
                                <Text>Asiakkaan tarpeet</Text>
                            </Center>
                        </GridItem>
                        <GridItem colSpan={"1"} rowSpan={"3"} border="1px" borderColor={"red"}>
                            <Center h="100%">
                                <Text>Kokemus</Text>
                            </Center>
                        </GridItem>
                        <GridItem colSpan={"1"} rowSpan={"3"} border="1px" borderColor={"red"}>
                            <Center h="100%">
                                <Text>Työkalut</Text>
                            </Center>
                        </GridItem>
                        <GridItem colSpan={"1"} rowSpan={"3"} border="1px" borderColor={"red"}>
                            <Center h="100%">
                                <Text>Hinnoittelu, hinnoittelumallit</Text>
                            </Center>
                        </GridItem>
                    </Grid>
                </ModalBody>
                <ModalFooter>
                    <Button color="white" bg="black" variant="outline" size="md" mt="4" onClick={onClose}>
                        Close (ESC)
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    </>
}

export default ServiceModal;