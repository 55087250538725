import { useEffect, useState } from 'react';

const PreloadImage = ({ src, alt, children }) => {
    const [isLoaded, setIsLoaded] = useState(false);
  
    useEffect(() => {
      const image = new Image();
      image.onload = () => {
        setIsLoaded(true);
      };
      image.src = src;
    }, [src]);
  
    return isLoaded ? children : null;
  };
  
  export default PreloadImage;