import { extendTheme } from '@chakra-ui/react'


const theme = extendTheme(
  {
    components: {
      Link: {
        baseStyle:{
          fontSize: '2xl'
        },
        variants: {
          'footer-link': {
            color: 'white',
            fontSize: 'lg',
            textDecoration: 'underline',
            _hover: {
              color: 'white',
              textDecoration: 'none'
            }
          },
          'footer-link-contact': {
            color: 'white',
            fontSize: 'sm',
            textDecoration: 'underline',
            _hover: {
              color: 'white',
              textDecoration: 'none'
            }
          }
        }
      },
      Input: {
        baseStyle: {
            field:{
              w: "400px",
              borderColor: "black",
              border: "1px",
              margin: "1px",
            },
            addon: {
              border: "1px",
              borderColor: "black",
              color: "black",
            },
            element: {

            }
        },
        variants: {
          'contact': {
            field:{
              color: 'black',
            },
            addon: {
            },
            element: {
            }
          }
        } 
      },
      Button: {
        baseStyle: {
          color: "white",
          bg: "black",
          border: "0px", 
          borderColor: "black",
          _hover: {
            bg: "white",
            color: "black",
            border: "1px", 
            borderColor: "black",
          }
        }
      },
      Textarea: {
        baseStyle: {
          borderColor: "black",
          focusBorderColor: "red",
          mt: "15px",
        }
      }
    },
    defaultProps: {
      focusBorderColor: 'black',
    }
  }
)

export default theme;