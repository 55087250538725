import {
    Box,
    Button,
    Link,
    Center
} from "@chakra-ui/react"; 

import { CopyIcon }  from '@chakra-ui/icons'
import { useState } from "react";
import { useToast } from "@chakra-ui/react";
import { useRef } from "react";

/**
 * 
 * @returns This function returns a component that shows a phone number. User can't see the number before clicking the component.
 */

function PhoneNumber({phoneNumber}){

    const containerRef = useRef(null);

    const [showNumber, setShowNumber] = useState(false);

    const handleMouseHover = () => setShowNumber(true);
    const toast = useToast();

    const handleClickCopy = () => {
        navigator.clipboard.writeText(phoneNumber);
        toast({
            position: 'bottom',
            duration: 1000,
            render: () => (
              <Center color='black' p={3} bg='white' w="500px" border={"1px"} borderColor={"black"}> 
                Puhelinnumero kopioitu leikepöydälle
              </Center>
            ),
          }) 
 
    }


    return (
        <Box ref={containerRef}>
        {!showNumber ? <>
            <Button onMouseEnter={handleMouseHover} colorScheme="whiteAlpha" variant="outline" h="24px" w="200px">
                Näytä puhelinnumero
            </Button>
        </>
            : <>
                <Box>
                    <CopyIcon color="white" marginInline={"15px"} onClick={handleClickCopy} />
                    <Link variant={"footer-link-contact"} href={"tel:"+phoneNumber}>{phoneNumber}</Link>
                </Box>
            </>

        }
        </Box>
    );
}

export default PhoneNumber;