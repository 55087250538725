import {
    Box,
    Grid,
    GridItem,
    Input,
    InputGroup,
    InputLeftAddon,
    Textarea,
    Button,
} from "@chakra-ui/react";

import {
  EmailIcon,
  PhoneIcon,
} from "@chakra-ui/icons";

import { BsFillPersonFill } from "react-icons/bs";

function ContactForm() {

  const tervehdys = "Jätä tähän yhdeydenottopyyntösi, niin otan sinuun yhteyttä mahdollisimman pian."

  return <>
      <Box w="40%" p="4" alignContent={"left"} margin="20px" >
        <Grid templateColumns="repeat(1, 5fr)">
          <GridItem>
            <InputGroup>
              <InputLeftAddon bg="white">
                <BsFillPersonFill/>
              </InputLeftAddon>
              <Input variant={"contact"} placeholder="Etunimi Sukunimi"/> 
            </InputGroup>
          </GridItem>
          <GridItem>
            <InputGroup>
              <InputLeftAddon bg="white">
                <EmailIcon/>
              </InputLeftAddon>
              <Input variant="contact" placeholder="etunimi.sukunimi@yritys.fi"/>
            </InputGroup>
          </GridItem>
          <GridItem>
            <InputGroup>
              <InputLeftAddon bg="white">
                <PhoneIcon/>
              </InputLeftAddon>
              <Input variant="contact" placeholder="+358 40 123 456"/>
            </InputGroup>
          </GridItem>
          <GridItem>
            <Textarea placeholder={tervehdys} w="100%" h="300px" />
          </GridItem>
          <GridItem>
            <Button color="white" bg="black" variant="outline" size="md" mt="4">
              Lähetä
            </Button>
          </GridItem>
        </Grid>
      </Box>
  </>
}

export default ContactForm;