import { Box } from "@chakra-ui/react";
import '../css/header.css'

import { useLocation } from 'react-router-dom';
import {
  useState,
  useLayoutEffect
} from "react";

import PreloadImage from "../components/PreloadImage";

let style = {
    backgroundRepeat:"no-repeat",
    backgroundSize: 'cover',
    backgroundPositionY: "center",
    borderBottom: 'solid',
    borderTop: 'solid',
    borderWidth: "2",
    borderColor: "black"
  }



function BlogiPage(props){
  const location = useLocation();
  const [displayLocation, setDisplayLocation] = useState(location);
  const [transitionStage, setTransistionStage] = useState("fadeIn");

  useLayoutEffect(() => {
    if (location !== displayLocation) setTransistionStage("fadeOut");
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  return <>
    <Box h="400px">
      <PreloadImage src={props.image}>
        <Box h="400px" bgImage={props.image} style={style}  boxShadow={"2xl"} bgColor={"#000"} className={transitionStage} onAnimationEnd={() => {
          if (transitionStage === "fadeOut") {
            setTransistionStage("fadeIn");
            setDisplayLocation(location);
          }
        }}/>
      </PreloadImage>
    </Box>
  </>
}

export default BlogiPage;