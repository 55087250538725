import {
    Box,
    Button,
    Link,
    Center
} from "@chakra-ui/react"; 

import { CopyIcon }  from '@chakra-ui/icons'
import { useState } from "react";
import { useToast } from '@chakra-ui/react'

/**
 * 
 * @returns This function returns a component that shows a phone number. User can't see the number before clicking the component.
 */

function Email({email}){
    const [showNumber, setShowEmail] = useState(false);
    const handleMouseHover = () => setShowEmail(true);
    
    const toast = useToast();
    /**
     * This function copies text to clipboard and shows a toast message.
     */
    const handleClickCopy = () => {
        navigator.clipboard.writeText(email);
        toast({
            position: 'bottom',
            duration: 1000,
            render: () => (
              <Center color='black' p={3} bg='white' w="500px" border={"1px"} borderColor={"black"}> 
                Sähköpostiosoite kopioitu leikepöydälle
              </Center>
            ),
          }) 
    }

    return (
        <Box>
        {!showNumber ? <>
            <Button onMouseEnter={handleMouseHover} colorScheme="whiteAlpha" variant="outline" h="24px" w="200px">
                Näytä sähköposti
            </Button>
        </>
            : <>
                <Box>
                    <CopyIcon color="white" marginInline={"15px"} onClick={handleClickCopy}/>
                    <Link variant={"footer-link-contact"} href={"mailto:" + email}>{email}</Link>
                </Box>
            </>

        }
        </Box>
    );
}

export default Email;