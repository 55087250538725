import React from 'react';
import {
  ChakraProvider,
} from '@chakra-ui/react';

import { BrowserRouter, Routes, Route } from "react-router-dom";

import Header from './components/Header';
import Footer from './components/Footer';
import image1 from "./imgs/business1.jpg";
import image2 from "./imgs/business2.jpg";
import image3 from "./imgs/business3.jpg";
import image4 from "./imgs/business4.jpg";


import MainPage from './pages/MainPage';
import PalvelutPage from './pages/PalvelutPage';
import BlogiPage from './pages/BlogiPage';
import YhteystiedotPage from './pages/YhteystiedotPage';

import theme from './theme';

function App() {
  
  return (
      <BrowserRouter>
        <ChakraProvider theme={theme}>
          <Header />
          <Routes>
            <Route path="/yhteydenotto" element={ <YhteystiedotPage image={image4} /> } />
            <Route path="/palvelut" element={ <PalvelutPage image={image3} /> } />
            <Route path="/blogi" element={ <BlogiPage image={image2}/> } />
            <Route path="/" element={ <MainPage image={image1}/> } />
          </Routes>
          <Footer />
        </ChakraProvider>
      </BrowserRouter>
  );
}

export default App;
