import {
  Box,
  GridItem,
  Grid,
  Center,
} from "@chakra-ui/react";
import '../css/header.css'

import { useLocation } from 'react-router-dom';
import {
  useState,
  useLayoutEffect
} from "react";

import { animateScroll } from "react-scroll";


import PreloadImage from "../components/PreloadImage";
import Service from "../components/Service";
import { SiAnsible } from "react-icons/si";
import { SiPython } from "react-icons/si";
import { SiKalilinux } from 'react-icons/si';
import { SiRobotframework } from 'react-icons/si';

let style = {
    backgroundRepeat:"no-repeat",
    backgroundSize: 'cover',
    backgroundPositionY: "center",
    borderBottom: 'solid',
    borderTop: 'solid',
    borderWidth: "2",
    borderColor: "black",
  }


function PalvelutPage(props){
  const location = useLocation();
  const [displayLocation, setDisplayLocation] = useState(location);
  const [transitionStage, setTransistionStage] = useState("fadeIn");

  useLayoutEffect(() => {
    if (location.pathname !== displayLocation.pathname) setTransistionStage("fadeOut");
    console.log(location);
    if (location.hasOwnProperty("hash")){
      if (location.hash !== ""){
        let element = document.getElementById(location.hash.substring(1));
        if (element !== null) {
          const options = {
            duration: 500,
            delay: 100,
            smooth: "easeOutQuad", // possible options: linear, easeInQuad, easeOutQuad, easeInOutQuad, easeInCubic, easeOutCubic, easeInOutCubic,easeInQuart, easeOutQuart, easeInOutQuart, easeInQuint, easeOutQuint, easeInOutQuint, easeInSine,easeOutSine, easeInOutSine, easeInExpo, easeOutExpo, easeInOutExpo, easeInCirc,easeOutCirc, easeInOutCirc, easeInBack, easeOutBack, easeInOutBack,
            containerId: "ContainerElementID",
          } 
          animateScroll.scrollTo(element.offsetTop - 100, options); 
          // Flash background color of the element to indicate that it has been scrolled to.
          // Color transition is soft and lasts for 1 second.
          element.style.transition = "background-color 1s ease";
          element.style.backgroundColor = "cyan";
          setTimeout(() => {
            element.style.backgroundColor = "";
          }, 500);
       }
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])
  
  var ansible="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam"
  ansible +=  " " + ansible


  return <>
    <Box h="400px">
      <PreloadImage src={props.image}>
        <Box h="400px" bgImage={props.image} style={style} boxShadow={"2xl"} bgColor={"#000"} className={transitionStage} onAnimationEnd={() => {
          if (transitionStage === "fadeOut") {
            setTransistionStage("fadeIn");
            setDisplayLocation(location);
          }
        }}/>
      </PreloadImage>
    </Box>
    <Center margin="20px">
      <Grid w="100%">
        <GridItem>
          <Service icon={SiPython} title={"Fullstack development"} text={ansible} anchor="fullstack"/>
        </GridItem>
        <GridItem>
          <Service icon={SiKalilinux} title={"Tietoturvapalvelut"} text={ansible} anchor="tietoturva"/>
        </GridItem>
        <GridItem>
          <Service icon={SiAnsible} title={"Infrastruktuuriautomaatio"} text={ansible} anchor="ansible"/>
        </GridItem>
        <GridItem>
          <Service icon={SiRobotframework} title={"Automaatiotestaus"} text={ansible} anchor="robotframework"/>
        </GridItem>
      </Grid>
    </Center>
  </>
}

export default PalvelutPage