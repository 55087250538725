import { 
  Flex,
  Center,
  Spacer,
  Grid,
  GridItem,
  Link,
  Image
} from '@chakra-ui/react'

import logo from "../imgs/logo-black.svg"
import { useEffect, useState} from 'react'
import { HamburgerIcon } from '@chakra-ui/icons'

function Header() { 
  const [width, setWidth] = useState(window.innerWidth);


  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth)
    }
    window.addEventListener('resize', handleResize);
  })

  function detectMob() {
    const toMatch = [
        /Android/i,
        /webOS/i,
        /iPhone/i,
        /iPad/i,
        /iPod/i,
        /BlackBerry/i,
        /Windows Phone/i
    ];
  
    return toMatch.some((toMatchItem) => {
        return navigator.userAgent.match(toMatchItem);
    });
  }

  useEffect(() => {
    detectMob();
  }, [])


  return <>
    <Flex h="250px" bg="white" w="100%">
      <Center w='250px' bg='Green.500' marginInline={"100px"}>
        <Image src={logo} objectFit='cover' h="100%" />
      </Center>
      <Spacer />
      { width > 1000 ? 
      <Center paddingInline={"50px"}>
        <Grid templateColumns='repeat(5, 1fr)' gap={6}>
          <GridItem w='100%' h='10' bg='white' >  
            <Center h="100%">
              <Link href='/' size={"custom"}>🧔🏻 Etusivu</Link>
            </Center>
          </GridItem>
          <GridItem w='100%' h='10' bg='white'>
            <Center h="100%">
            <Link href='/palvelut'>🛎 Palvelut</Link>
            </Center>
          </GridItem>
          <GridItem w='100%' h='10' bg='white'>
            <Center h="100%">
              <Link href='/blogi'>💻 Blogi</Link>
            </Center>
          </GridItem>
          <GridItem w='100%' h='10' bg='white'>
            <Center h="100%">
            <Link href='/yhteydenotto'>📧 Yhteydenotto</Link>
            </Center>
          </GridItem>
        </Grid>
      </Center>
      :
      <Center h="100%" paddingInline={"75px"}>
        <HamburgerIcon boxSize="100" color="#000"/>
      </Center>
      }
      <Spacer />
    </Flex>
  </>
}


  export default Header;