import {
    Box,
    Text, 
    Center
 } from "@chakra-ui/react";
import '../css/header.css'
import {
    useState,
    useEffect,
    useLayoutEffect
} from "react";

import { useLocation } from 'react-router-dom';

import PreloadImage from "../components/PreloadImage";


function MainPage(props){
    const [data, setData] = useState();

    const location = useLocation();
    const [displayLocation, setDisplayLocation] = useState(location);
    const [transitionStage, setTransistionStage] = useState("fadeIn");

    useLayoutEffect(() => {
        //if (location !== displayLocation) setTransistionStage("fadeOut");
        if (location !== displayLocation) setTransistionStage("fadeOut");
    // eslint-disable-next-line react-hooks/exhaustive-deps  
    }, [location])

    useEffect(() => {
        getData();
      }, []);
   
    function getData(){
        fetch('https://mikkohallikainen.fi/cms/en/api/v1/testing', {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            // Add any additional headers if required
        },
        })
        .then(response => response.json())
        .then(data => {
            setData(data[0].mydata);
        })
        .catch(error => {
            // Handle any errors
            console.error(error);
        }
    );
        //setData();
    }

    let style = {
        backgroundRepeat:"no-repeat",
        backgroundSize: 'cover',
        backgroundPositionY: "center",
        borderBottom: 'solid',
        borderTop: 'solid',
        borderWidth: "2",
        borderColor: "black"
    }
    return <>
        <Box h="400px">
            <PreloadImage src={props.image}>
                <Box h="400px" bgImage={props.image} style={style} boxShadow={"2xl"} bgColor={"#000"} className={transitionStage} onAnimationEnd={() => {
                if (transitionStage === "fadeOut") {
                setTransistionStage("fadeIn");
                setDisplayLocation(location);
                }
                }}/>
            </PreloadImage>
        </Box>  
        <Center  marginBlock={"50px"}>
            <Text w={"50%"} align={"justify"} casing={"revert-layer"}>{data}</Text>
        </Center>
    </>
}

export default MainPage