import {
    Flex,
    Box,
    Spacer,
    Icon,
    Center,
    Grid,
    GridItem,
    VStack,
    Container,
    Link,
 } from "@chakra-ui/react";



import { FaLinkedin } from 'react-icons/fa';
import { SiAnsible } from "react-icons/si";
import { LuConciergeBell } from "react-icons/lu";
import { BsFillHouseDoorFill } from "react-icons/bs";
import { ImBlog } from "react-icons/im";
import { BsFillEnvelopePlusFill } from "react-icons/bs";
import { SiPython } from "react-icons/si";

import { SiRobotframework } from 'react-icons/si';
import { SiKalilinux } from 'react-icons/si';

import PhoneNumber from "./PhoneNumber";
import Email from "./Email";


/**
 * 
 * @returns Function that return footer component which includes following content:
 *  - contact information
 *  - references
 *  - social media links
 *  - navigation links
 * 
 *  Footer component's backgroud color is black.
 *  Footer components are aligned to center horizontally.
 */
function Footer(){
    return <> 
        <Box width={"100%"} bg={"black"}>
            <Center>
                <Flex bg="black" h="250px" w="90%">
                    <Spacer />
                    <VStack w="100%" h="250px">
                        <Container w="100%" h="100%" centerContent padding={"20px"}>
                            <Grid templateColumns='repeat(1, 4)' gap={4} w="100%" h="100%" borderRight={"1px"} borderColor={"white"}>
                                <GridItem w='100%' h='10'>
                                    <Flex>
                                        <Spacer />
                                            <Box h="100%" w="50%">
                                                <Flex>
                                                    <Icon as={BsFillHouseDoorFill} color={"white"} marginInlineEnd="15px" w="25px" h="25px" alignSelf={"center"}/>
                                                    <Link variant={"footer-link"} href="/">Etusivu</Link>
                                                    <Spacer/>
                                                </Flex>
                                            </Box>
                                        <Spacer/>
                                    </Flex>
                                </GridItem>
                                <GridItem w='100%' h='10'>
                                    <Flex>
                                        <Spacer />
                                            <Box h="100%" w="50%">
                                                <Flex>
                                                    <Icon as={LuConciergeBell} color="white" marginInlineEnd="15px" w="25px" h="25px" alignSelf={"center"}/>
                                                    <Link variant={"footer-link"} href="/palvelut">Palvelut</Link>
                                                    <Spacer/>
                                                </Flex>
                                            </Box>
                                        <Spacer/>
                                    </Flex> 
                                </GridItem>
                                <GridItem w='100%' h='10'>
                                    <Flex>
                                        <Spacer />
                                        <Box h="100%" w="50%">
                                            <Flex>
                                                <Icon as={ImBlog} color="white" marginInlineEnd="15px" w="25px" h="25px" alignSelf={"center"}/>
                                                <Link variant={"footer-link"} href="/blogi">Blogi</Link>
                                                <Spacer/>
                                            </Flex>
                                        </Box>
                                        <Spacer/>
                                    </Flex>
                                </GridItem>
                                <GridItem w='100%' h='10'>
                                    <Flex>
                                        <Spacer />
                                        <Box h="100%" w="50%">
                                            <Flex>
                                                <Icon as={BsFillEnvelopePlusFill} color="white" marginInlineEnd="15px" w="25px" h="25px" alignSelf={"center"}/>
                                                <Link variant={"footer-link"} href="/yhteydenotto">Yhteydenotto</Link>
                                                <Spacer/>
                                            </Flex>
                                        </Box>
                                        <Spacer/>
                                    </Flex>
                                </GridItem>
                            </Grid>
                        </Container>
                    </VStack>
                    <Spacer />
                    <VStack w="100%" h="250px">
                        <Container w="100%" h="100%" centerContent padding={"20px"}>
                            <Grid templateColumns='repeat(1, 4)' gap={4} w="100%" h="100%" borderRight={"1px"} borderColor={"white"}>
                                <GridItem w='100%' h='10'>
                                    <Flex>
                                        <Spacer />
                                            <Box h="100%" w="50%">
                                                <Flex>
                                                    <Icon as={SiAnsible} color="white" marginInlineEnd="15px" w="25px" h="25px" alignSelf={"center"}/>
                                                    <Link variant={"footer-link"} href="/palvelut#ansible">Ansible</Link> 
                                                    <Spacer/>
                                                </Flex>
                                            </Box>
                                        <Spacer/>
                                    </Flex>
                                </GridItem>
                                <GridItem w='100%' h='10'>
                                    <Flex>
                                        <Spacer />
                                            <Box h="100%" w="50%">
                                                <Flex>
                                                    <Icon as={SiRobotframework} color="white" marginInlineEnd="15px" w="25px" h="25px" alignSelf={"center"}/>
                                                    <Link variant={"footer-link"} href="/palvelut#robotframework">Robotframework</Link>
                                                    <Spacer/>
                                                </Flex>
                                            </Box>
                                        <Spacer/>
                                    </Flex> 
                                </GridItem>
                                <GridItem w='100%' h='10'>
                                    <Flex>
                                        <Spacer />
                                        <Box h="100%" w="50%">
                                            <Flex>
                                                <Icon as={ SiPython } color="white" marginInlineEnd="15px" w="25px" h="25px" alignSelf={"center"}/>
                                                <Link variant={"footer-link"} href="/palvelut#fullstack">Full Stack Development</Link>
                                                <Spacer/>
                                            </Flex>
                                        </Box>
                                        <Spacer/>
                                    </Flex>
                                </GridItem>
                                <GridItem w='100%' h='10'>
                                    <Flex>
                                        <Spacer />
                                        <Box h="100%" w="50%">
                                            <Flex>
                                                <Icon as={SiKalilinux} color="white" marginInlineEnd="15px" w="25px" h="25px" alignSelf={"center"}/>
                                                <Link variant={"footer-link"} href="/palvelut#tietoturva">Tietoturvapalvelut</Link>
                                                <Spacer/>
                                            </Flex> 
                                        </Box>
                                        <Spacer/>
                                    </Flex>
                                </GridItem>
                            </Grid>
                        </Container>
                    </VStack>
                    <Spacer />
                    <VStack w="100%" h="250px">
                        <Container w="100%" h="100%" centerContent padding={"20px"}>
                            <Grid templateColumns='repeat(1, 4)' gap={4} w="100%" h="100%"> 
                                <GridItem w='100%' h='10'>
                                    <Flex>
                                        <Spacer />
                                            <Box h="100%" w="50%">
                                                <Flex>
                                                    <Icon as={FaLinkedin} color="white" marginInlineEnd="15px" w="25px" h="25px" alignSelf={"center"}/>
                                                    <Link variant={"footer-link"} href="https://www.linkedin.com/in/mikkohallikainen/">LinkedIn</Link>
                                                    <Spacer/>
                                                </Flex>
                                            </Box>
                                        <Spacer/>
                                    </Flex>
                                </GridItem>
                                <GridItem w='100%' h='10'>
                                    <Flex>
                                        <Spacer />
                                            <Box h="100%" w="50%">
                                                <Email email="mikko@mikkohallikainen.fi">Sähköposti</Email>
                                            </Box>
                                        <Spacer/>
                                    </Flex> 
                                </GridItem>
                                <GridItem w='100%' h='10'>
                                    <Flex>
                                        <Spacer />
                                        <Box h="100%" w="50%">
                                                <PhoneNumber phoneNumber="+358 400 305 138"/>
                                        </Box>
                                        <Spacer/>
                                    </Flex>
                                </GridItem>
                                <GridItem w='100%' h='10'>
                                    <Flex>
                                        <Spacer />
                                        <Box h="100%" w="50%">
                                            <Link  href="/yhteydenotto" variant={"footer-link"}>Yhteydenotto</Link>
                                        </Box>
                                        <Spacer/>
                                    </Flex>
                                </GridItem>
                            </Grid>
                        </Container>
                    </VStack>
                    <Spacer />
                </Flex>
            </Center>
        </Box>
    </>

}


export default Footer;