    import {
    Box,
    Center,
    Text,
    Icon, 
    Grid,
    GridItem,
    Heading,
    Button
} from "@chakra-ui/react";
import ServiceModal from "./ServiceModal";
import { useDisclosure } from "@chakra-ui/react"

function Service({icon, title, text, anchor}) {
    const { isOpen, onOpen, onClose } = useDisclosure()
    return <>
        <Center> 
            <Box
                w="55%"
                border="1px"
                borderColor={"black"}
                borderRadius={"3xl"}
                margin="20px"
                id={anchor}
            >
                <Grid templateColumns="repeat(2, 3fr)" w="100%" padding={"20px"}>
                    <GridItem colSpan={"1"} rowSpan={"3"}>
                        <Center>
                            <Icon
                                as={icon}
                                color="Black"
                                marginInlineEnd="15px"
                                w="250px"
                                h="250px"
                                alignSelf={"center"}
                                margin="20px"
                            />
                        </Center>
                    </GridItem>
                    <GridItem colSpan={"1"} rowSpan={"1"}>
                        <Center h="100%">
                            <Heading>{title}</Heading>
                        </Center>
                    </GridItem>
                    <GridItem colSpan={"1"} rowSpan={"2"}>
                        <Box h="100%">
                            <Text>{text}</Text>
                            <Button onClick={onOpen} color="white" bg="black" variant="outline" size="md" mt="4">
                                Lisää tietoa
                            </Button>
                        </Box>
                    </GridItem>
                </Grid>
            </Box>
        </Center>
        <ServiceModal isOpen={isOpen} onClose={onClose} title={title}/>
    </>
}

export default Service;

