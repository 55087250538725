import Email from "./Email";
import PhoneNumber from "./PhoneNumber";
import {
    Box,
    Center, 
    Grid,
    GridItem,
    Image,
    Text
} from "@chakra-ui/react";

import img_mikko from "../imgs/mikko.jpg";
import PreloadImage from "./PreloadImage";

function ContactInfo() {
  return <>
    <Center h="100%">
        <Box bg="black" h="90%" w="70%" margin="20px" borderRadius={"xl"}>
        <PreloadImage src={img_mikko}>
            <Image
                src={img_mikko}
                borderTopRadius={"xl"}
                boxSize={"200px"}
                objectFit={"cover"}
                w="100%"
                h="400px"
                filter="grayscale(100%)"
            />
        </PreloadImage>
            <Grid templateColumns="repeat(2, 2fr)" w="100%">
                <GridItem rowSpan={"2"} colSpan={"1"}>
                    <Box margin="10px" padding={"5px"}>
                        <Text color="white" fontSize={"sm"}>Yrittäjä, Botora Oy</Text>
                        <Text color="white" fontSize={"sm"}>Mikko Hallikainen</Text>
                        <Text color="white" fontSize={"sm"}>Y-tunnus: 3320643-5</Text>
                        <Text color="white" fontSize={"sm"}>Kotipaikka: Vantaa</Text>
                    </Box>
                </GridItem>
                <GridItem rowSpan={"2"} colSpan={"1"}>
                    <Box margin="20px">
                        <Email email="mikko@mikkohallikainen.fi"/>
                    </Box>
                    <Box margin="20px">
                        <PhoneNumber phoneNumber="+358 400 305 138"/>
                    </Box>
                </GridItem>
            </Grid>
        </Box>
    </Center>
  </>
}

export default ContactInfo;